<template>
  <header class="bg-darkgray-800 w-full z-20 shadow-xl">
    <nav class="mx-auto flex max-w-8xl items-center justify-between p-6 lg:px-8" aria-label="Global">
      <div class="flex lg:flex-1">
        <NuxtLink to="/" class="shrink-0 -m-1.5">
          <span class="sr-only">MattePaint</span>
          <img src="~/assets/img/logo_full.png" class="h-9 hidden lg:flex shrink-0" alt="MattePaint Logo" />
          <img src="~/assets/img/icon_white.png" loading="lazy" class="h-9 flex lg:hidden shrink-0" alt="MattePaint Icon" />
        </NuxtLink>
      </div>
      <div class="flex lg:hidden">
        <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white" @click="mobileMenuOpen = true">
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div class="hidden lg:flex lg:gap-x-6 text-white">
        <div v-for="item in navigation.filter(r => !r.mobile)" :key="item.name" class="text-base uppercase font-semibold leading-6 text-white">
          <a v-if="!item.nuxt" :href="item.href" class="flex items-center space-x-1 hover:underline">
            <svg
              v-if="item.showIcon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2004_495)">
                <rect width="15" height="15" transform="translate(0.756836 0.5)" fill="#030303" />
                <path d="M15.5518 13.4697L12.6309 10.5488C12.499 10.417 12.3203 10.3438 12.1328 10.3438H11.6553C12.4639 9.30957 12.9443 8.00879 12.9443 6.59375C12.9443 3.22754 10.2168 0.5 6.85058 0.5C3.48437 0.5 0.756836 3.22754 0.756836 6.59375C0.756836 9.95996 3.48437 12.6875 6.85058 12.6875C8.26562 12.6875 9.56641 12.207 10.6006 11.3984V11.876C10.6006 12.0635 10.6738 12.2422 10.8057 12.374L13.7266 15.2949C14.002 15.5703 14.4473 15.5703 14.7197 15.2949L15.5488 14.4658C15.8242 14.1904 15.8242 13.7451 15.5518 13.4697ZM6.85058 10.3438C4.7793 10.3438 3.10059 8.66797 3.10059 6.59375C3.10059 4.52246 4.77637 2.84375 6.85058 2.84375C8.92187 2.84375 10.6006 4.51953 10.6006 6.59375C10.6006 8.66504 8.9248 10.3438 6.85058 10.3438Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_2004_495">
                  <rect width="15" height="15" fill="white" transform="translate(0.756836 0.5)" />
                </clipPath>
              </defs>
            </svg>
            <span>{{ item.name }}</span>
          </a>
          <nuxt-link v-else :to="item.href" class="flex items-center space-x-1 hover:underline">
            <svg
              v-if="item.showIcon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2004_495)">
                <rect width="15" height="15" transform="translate(0.756836 0.5)" fill="#030303" />
                <path d="M15.5518 13.4697L12.6309 10.5488C12.499 10.417 12.3203 10.3438 12.1328 10.3438H11.6553C12.4639 9.30957 12.9443 8.00879 12.9443 6.59375C12.9443 3.22754 10.2168 0.5 6.85058 0.5C3.48437 0.5 0.756836 3.22754 0.756836 6.59375C0.756836 9.95996 3.48437 12.6875 6.85058 12.6875C8.26562 12.6875 9.56641 12.207 10.6006 11.3984V11.876C10.6006 12.0635 10.6738 12.2422 10.8057 12.374L13.7266 15.2949C14.002 15.5703 14.4473 15.5703 14.7197 15.2949L15.5488 14.4658C15.8242 14.1904 15.8242 13.7451 15.5518 13.4697ZM6.85058 10.3438C4.7793 10.3438 3.10059 8.66797 3.10059 6.59375C3.10059 4.52246 4.77637 2.84375 6.85058 2.84375C8.92187 2.84375 10.6006 4.51953 10.6006 6.59375C10.6006 8.66504 8.9248 10.3438 6.85058 10.3438Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_2004_495">
                  <rect width="15" height="15" fill="white" transform="translate(0.756836 0.5)" />
                </clipPath>
              </defs>
            </svg>
            <span>{{ item.name }}</span>
          </nuxt-link>
        </div>
      </div>
      <div class="hidden lg:flex lg:flex-1 lg:justify-end">
        <ClientOnly>
          <div class="flex space-x-6 items-center">
            <!-- Separator -->
            <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-100/10" aria-hidden="true" />

            <!-- Profile dropdown -->
            <HeaderAccountMenu v-if="isAuthed" />
            <NuxtLink v-else to="/login/" class="text-sm font-semibold leading-6 text-white">
              SIGN IN <span aria-hidden="true">&rarr;</span>
            </NuxtLink>
          </div>
        </ClientOnly>
      </div>
    </nav>
    <ClientOnly>
      <Dialog as="div" class="lg:hidden" :open="mobileMenuOpen" @close="mobileMenuOpen = false">
        <div class="fixed inset-0 z-10" />
        <DialogPanel focus="true" class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-black-800 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div class="flex items-center justify-between">
            <NuxtLink to="/" class="shrink-0 -m-1.5">
              <span class="sr-only">MattePaint</span>
              <img src="~/assets/img/icon_white.png" loading="lazy" class="h-9 flex shrink-0" />
            </NuxtLink>
            <button type="button" class="-m-2.5 rounded-md p-2.5 text-white" @click="mobileMenuOpen = false">
              <span class="sr-only">Close menu</span>
              <XMarkIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="mt-6 flow-root">
            <div class="-my-6 divide-y divide-gray-500/10">
              <div class="space-y-2 py-6">
                <div v-for="item in navigation" :key="item.name" class="-mx-3 block rounded-lg text-base font-semibold leading-7 text-white hover:bg-gray-400">
                  <a v-if="!item.nuxt" noPrefetch :href="item.href" class="py-2 px-3 block">{{ item.name }}</a>
                  <nuxt-link v-else :to="item.href" class="py-2 px-3 block" @click="mobileMenuOpen = false">
                    {{ item.name }}
                  </nuxt-link>
                </div>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </ClientOnly>
  </header>
</template>

<script>

import { Dialog, DialogPanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { Bars3Icon, XMarkIcon, ChevronDownIcon } from '@heroicons/vue/24/outline';

import icon from '~/assets/img/icon_white.png';

export default {
  components: { Dialog, DialogPanel, Menu, MenuButton, MenuItem, MenuItems, Bars3Icon, XMarkIcon, ChevronDownIcon },
  data() {
    return {
      icon,
      mobileMenuOpen: false,
      navigation: [
        { name: 'Home', href: '/', nuxt: true, mobile: true },
        { name: 'Library', href: 'https://mattepaint.com/gallery/', showIcon: true },
        { name: 'HDRI\'s', href: 'https://mattepaint.com/gallery/hdri/' },
        { name: 'Academy', href: 'https://mattepaint.com/academy/' },
        { name: 'Pricing', href: '/plans/', nuxt: true },
        { name: 'Licensing', href: '/licensing/', nuxt: true },
        { name: 'Blog', href: 'https://mattepaint.com/blog/' },
        { name: 'Privacy Policy', href: 'https://mattepaint.com/privacy/', mobile: true },
        { name: 'Terms of Use', href: 'https://mattepaint.com/tos/', mobile: true },
      ],
    }
  },
  computed: {
    userNavigation() {
      const userNavigation = [
        { name: 'Your account', href: '/account/', onClick: () => this.yourAccount() },
        // { name: 'Your profile', href: 'https://mattepaint.com/gallery/account/', onClick: () => navigateTo('https://mattepaint.com/gallery/account/', { external: true }) },
        { name: 'Sign out', href: '/logout/', onClick: () => this.logout() },
      ]
      if (this.user && !this.user.subscription) {
        userNavigation.unshift({ name: 'Purchase Subscription', href: '/plans/', onClick: () => navigateTo('/plans/') })
      }
      return userNavigation;
    }
  },
  methods: {
    yourAccount() {
      navigateTo('/account/')
    },
    async logout() {
      await this.$store.auth.Logout();
      window.location.href = '/';
    }
  }
}
</script>